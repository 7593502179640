@keyframes ball-scale-multiple {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.ball-scale-multiple {
  position: relative;
  transform: translateY(-30px);
}
.ball-scale-multiple > div:nth-child(2) {
  animation-delay: -0.4s;
}
.ball-scale-multiple > div:nth-child(3) {
  animation-delay: -0.2s;
}
.ball-scale-multiple > div {
  background-color: #3a7faa;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  position: absolute;
  left: -30px;
  top: 0px;
  opacity: 0;
  margin: 0;
  width: 60px;
  height: 60px;
  animation: ball-scale-multiple 1s 0s linear infinite;
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: fixed;
  z-index: 99999;
  height: 2em;
  width: 2em;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 99999999;
}
