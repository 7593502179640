/** @format */

// stylelint-disable no-duplicate-selectors, selector-no-qualifying-type
//
// Grid examples
//
.ct-example-row {
	.row {
		> .col,
		> [class^="col-"] {
			span {
				display: block;
				padding: 0.75rem;
				color: rgb(57, 63, 73);
				background-color: rgb(255, 255, 255);
				box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
					rgba(0, 0, 0, 0.1) 0px 4px 16px;
				font-size: $font-size-sm;
				border-radius: 0.25rem;
				margin: 1rem 0;
			}
		}
	}
	.no-gutters {
		> .col,
		> [class^="col-"] {
			span {
				border-radius: 0;
			}
		}
	}
	.flex-items-top,
	.flex-items-middle,
	.flex-items-bottom {
		min-height: 6rem;
		background-color: rgba(255, 0, 0, 0.1);
	}
}

.ct-example-row-flex-cols .row {
	min-height: 10rem;
	background-color: rgba(255, 0, 0, 0.1);
	& + .row {
		margin-top: 1rem;
	}
}

.ct-highlight {
	background-color: rgba($ct-primary, 0.15);
	border: 1px solid rgba($ct-primary, 0.15);
}

// Grid mixins
.example-container {
	width: 800px;
	@include make-container();
}

.example-row {
	@include make-row();
}

.example-content-main {
	@include make-col-ready();
	@include media-breakpoint-up(sm) {
		@include make-col(6);
	}
	@include media-breakpoint-up(lg) {
		@include make-col(8);
	}
}

.example-content-secondary {
	@include make-col-ready();
	@include media-breakpoint-up(sm) {
		@include make-col(6);
	}
	@include media-breakpoint-up(lg) {
		@include make-col(4);
	}
}

//
// Container illustrations
//
.ct-example-container {
	min-width: 16rem;
	max-width: 25rem;
	margin-right: auto;
	margin-left: auto;
}

.ct-example-container-header {
	height: 3rem;
	margin-bottom: 0.5rem;
	background-color: lighten($blue, 50%);
	border-radius: 0.25rem;
}

.ct-example-container-sidebar {
	float: right;
	width: 4rem;
	height: 8rem;
	background-color: lighten($blue, 25%);
	border-radius: 0.25rem;
}

.ct-example-container-body {
	height: 8rem;
	margin-right: 4.5rem;
	background-color: lighten($ct-primary, 25%);
	border-radius: 0.25rem;
}

.ct-example-container-fluid {
	max-width: none;
}

//
// Docs examples
//
.ct-example {
	position: relative;
	margin: 1rem (-$grid-gutter-width / 2) 0;
	@include clearfix();
	@include media-breakpoint-up(sm) {
		margin-right: 0;
		margin-left: 0;
	}
	+ .highlight,
	+ .clipboard + .highlight {
		margin-top: 0;
	}
	+ p {
		margin-top: 2rem;
	}
	.pos-f-t {
		position: relative;
		margin: -1rem;
		@include media-breakpoint-up(sm) {
			margin: -1.5rem;
		}
	}
	.custom-file-input:lang(es) ~ .custom-file-label::after {
		content: "Elegir";
	}
	> .form-control {
		+ .form-control {
			margin-top: 0.5rem;
		}
	}
	> .nav + .nav,
	> .alert + .alert,
	> .navbar + .navbar,
	> .progress + .progress,
	> .progress + .btn,
	.badge,
	.btn {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
	.btn-group {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		.btn {
			margin: 0;
		}
	}
	.alert {
		margin: 0;
		+ .alert {
			margin-top: 1.25rem;
		}
	}
	.badge {
		margin-right: 0.5rem;
	}
	> .dropdown-menu:first-child {
		position: static;
		display: block;
	}
	> .form-group:last-child {
		margin-bottom: 0;
	}
	> .close {
		float: none;
	}
}

// Typography
.ct-example-type {
	.table {
		.type-info {
			color: #999;
			vertical-align: middle;
		}
		td {
			padding: 1rem 0;
			border-color: #eee;
		}
		tr:first-child td {
			border-top: 0;
		}
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 0;
		margin-bottom: 0;
	}
}

// Contextual background colors
.ct-example-bg-classes p {
	padding: 1rem;
}

// Images
.ct-example > img {
	+ img {
		margin-left: 0.5rem;
	}
}

// Buttons
.ct-example {
	> .btn-group {
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}
	> .btn-toolbar + .btn-toolbar {
		margin-top: 0.5rem;
	}
}

// Forms
.ct-example-control-sizing select,
.ct-example-control-sizing input[type="text"] + input[type="text"] {
	margin-top: 0.5rem;
}

.ct-example-form .input-group {
	margin-bottom: 0.5rem;
}

.ct-example > textarea.form-control {
	resize: vertical;
}

// List groups
.ct-example > .list-group {
	max-width: 400px;
}

// Navbars
.ct-example {
	.fixed-top,
	.sticky-top {
		position: static;
		margin: -1rem -1rem 1rem;
	}
	.fixed-bottom {
		position: static;
		margin: 1rem -1rem -1rem;
	}
	@include media-breakpoint-up(sm) {
		.fixed-top,
		.sticky-top {
			margin: -1.5rem -1.5rem 1rem;
		}
		.fixed-bottom {
			margin: 1rem -1.5rem -1.5rem;
		}
	}
}

// Pagination
.ct-example .pagination {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

// Example modals
.modal {
	z-index: 1072;
	.tooltip,
	.popover {
		z-index: 1073;
	}
}

.modal-backdrop {
	z-index: 1071;
}

.ct-example-modal {
	background-color: #fafafa;
	.modal {
		position: relative;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
		z-index: 1;
		display: block;
	}
	.modal-dialog {
		left: auto;
		margin-right: auto;
		margin-left: auto;
	}
}

// Example tabbable tabs
.ct-example-tabs .nav-tabs {
	margin-bottom: 1rem;
}

// Popovers
.ct-example-popover-static {
	padding-bottom: 1.5rem;
	background-color: #f9f9f9;
	.popover {
		position: relative;
		display: block;
		float: left;
		width: 260px;
		margin: 1.25rem;
	}
}

// Tooltips
.tooltip-demo a {
	white-space: nowrap;
}

.ct-example-tooltip-static .tooltip {
	position: relative;
	display: inline-block;
	margin: 10px 20px;
	opacity: 1;
}

// Scrollspy demo on fixed height div
.scrollspy-example {
	position: relative;
	height: 200px;
	margin-top: 0.5rem;
	overflow: auto;
}

.scrollspy-example-2 {
	position: relative;
	height: 350px;
	overflow: auto;
}

.ct-example-border-utils {
	[class^="border"] {
		display: inline-block;
		width: 5rem;
		height: 5rem;
		margin: 0.25rem;
		background-color: #f5f5f5;
	}
}

.ct-example-border-utils-0 {
	[class^="border"] {
		border: 1px solid $border-color;
	}
}

//
// Code snippets
//
.highlight {
	padding: 0;
	margin-top: 1rem;
	//margin-bottom: 3rem;
	//background-color: $gray-100;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	@include media-breakpoint-up(sm) {
		padding: 0;
	}
}

.ct-content .highlight {
	margin-right: (-$grid-gutter-width / 2);
	margin-left: (-$grid-gutter-width / 2);
	@include media-breakpoint-up(sm) {
		margin-right: 0;
		margin-left: 0;
	}
}

//
// Component-Code tabs
//
.ct-example {
	margin-bottom: 2rem;
	padding-bottom: 2rem;
	border-bottom: 1px solid $gray-200;
	.tab-content {
		.tab-example-result {
			background-color: #f5f7f9;
			border: 1px solid #e6ecf1;
			padding: 1.25rem;
			border-radius: 0.25rem;
		}
	}
	.nav-tabs-code {
		margin-bottom: 0.375rem;
		.nav-link {
			font-size: 0.875rem;
			&:active,
			&.active {
				color: $ct-primary;
			}
		}
	}
}

// Icon examples
.icon-examples {
	margin-top: 1rem;
}

.btn-icon-clipboard {
	margin: 0px;
	padding: 24px;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.25;
	color: rgb(57, 63, 73);
	background-color: rgb(248, 249, 250);
	border-radius: 4px;
	border: 0px none;
	text-align: left;
	font-family: inherit;
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
	-moz-appearance: none;
	cursor: pointer;
	width: 100%;
	margin: 0.5rem 0;
	&:hover {
		background-color: rgb(255, 255, 255);
		box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 16px;
	}
	> div {
		align-items: center;
		display: flex;
	}
	i {
		box-sizing: content-box;
		color: rgb(57, 63, 73);
		vertical-align: middle;
		font-size: 1.5rem;
	}
	span {
		display: inline-block;
		font-size: 0.875rem;
		line-height: 1.5;
		color: rgb(57, 63, 73);
		margin-left: 16px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		vertical-align: middle;
	}
}

.games-table {
	justify-content: center;
	display: flex;
	margin: 20px auto;
	input {
		max-width: 90px;
		max-height: 25px;
		font-size: 12px;
	}
}

.bet-table-container,
.single-row-table .single-row {
	overflow: auto;
}

.games-table div.bet-rows div,
.games-table div.result-row {
	display: flex;
}

.games-table {
	div.card {
		padding: 15px;
	}
}

.games-table div.bet-rows > div > div,
.games-table div.result-row > div {
	padding-right: 14px;
}

.games-table {
	div.bet-rows {
		> div:nth-child(1) {
			padding-left: 0px;
		}
	}
}

.games-table {
	div.result-row {
		padding-left: 0px;
	}
}

.games-table {
	div.bet-rows > div {
		margin-bottom: 6px;
	}
}

.games-table div.bet-rows > div:nth-child(1) > div.l-item,
.games-table div.single-row div.l-item {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.games-table div.bet-rows {
	div > div:nth-child(1) > input {
		margin-left: 4px;
	}
	.result-row {
		padding-left: 16px;
		margin-top: 15px;
	}
}

.games-table div.bet-rows div.l-item label,
.single-row-table .l-item label {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: bold;
}

.games-table div.bet-rows > div input,
.games-table div.result-row > div input {
	border-radius: 3px;
	margin: 0;
	background: #d6cdcd94;
	border: 1px solid grey;
	text-align: center;
}

.bet-table-container {
	display: flex;
}

.bet-table-container div.r-sec {
	display: flex;
	justify-content: center;
	writing-mode: vertical-rl;
}

.bet-table-container div.r-sec .total-bet {
	font-weight: bold;
}

.single-row-table {
	margin-top: 20px;
	.h-title h3 {
		margin: 0;
	}
	.h-title {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		background: #bad0babd;
		padding: 5px;
	}
}

.games-table div.single-row div.l-item {
	padding-right: 8px;
}
.auto-complete-search .MuiAutocomplete-root {
	width: 100%;
}
.auto-complete-search .MuiInputBase-formControl {
	padding: 0 !important;
}
#wallet_id {
	padding: 5px !important;
}
#wallet_id-label {
	font-size: 0.75rem;
	top: -8px;
}

@media screen and (min-width: 600px) {
	.games-table-mobile {
		display: none;
	}
}
@media screen and (max-width: 600px) {
	.games-table {
		display: none;
		justify-content: center;
		display: none;
		margin: 50px auto;
		input {
			max-width: 40px;
			max-height: 25px;
			font-size: 8px;
		}
	}
	.games-table-mobile {
		justify-content: center;
		// display: flex;
		max-width: 400px;
		margin: 10px auto;
		input {
			max-width: 30px;
			max-height: 25px;
			font-size: 8px;
		}
	}

	.bet-table-container-mobile,
	.single-row-table .single-row {
		overflow: auto;
	}

	.bet-table-container-mobile {
		display: flex;
	}

	.bet-table-container-mobile div.r-sec {
		display: flex;
		justify-content: center;
		writing-mode: vertical-rl;
	}

	.bet-table-container-mobile div.r-sec .total-bet {
		font-weight: bold;
	}

	.games-table-mobile div.bet-rows div,
	.games-table-mobile div.result-row {
		display: flex;
	}

	.games-table-mobile {
		div.card {
			padding: 15px;
		}
	}

	.games-table-mobile div.bet-rows > div > div,
	.games-table-mobile div.result-row > div {
		padding-right: 14px;
	}

	.games-table-mobile {
		div.bet-rows {
			> div:nth-child(1) {
				padding-left: 16px;
			}
		}
	}

	.games-table-mobile {
		div.result-row {
			padding-left: 20px;
		}
	}

	.games-table-mobile {
		div.bet-rows > div {
			margin-bottom: 6px;
		}
	}

	.games-table-mobile div.bet-rows > div:nth-child(1) > div.l-item,
	.games-table-mobile div.single-row div.l-item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.games-table-mobile div.bet-rows {
		div > div:nth-child(1) > input {
			margin-left: 4px;
		}
		.result-row {
			padding-left: 16px;
			margin-top: 15px;
		}
	}

	.games-table-mobile div.bet-rows div.l-item label,
	.single-row-table .l-item label {
		margin-bottom: 0;
		font-size: 14px;
		font-weight: bold;
	}

	.games-table-mobile div.bet-rows > div input,
	.games-table-mobile div.result-row > div input {
		border-radius: 3px;
		margin: 0;
		background: #d6cdcd94;
		border: 1px solid grey;
		text-align: center;
	}
}
